(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    customizeRadioBtn();
    function customizeRadioBtn() {
      var labels = $('.scfSectionContent .scfRadioButtonList tr td label');
      customizeRadioOrCheckbox(labels);
    }
    customizeCheckbox();
    function customizeCheckbox() {
      var labels = $('.scfSectionContent .scfCheckbox label');
      customizeRadioOrCheckbox(labels);
    }
    function customizeRadioOrCheckbox(container) {
      container.each(function () {
        var self = $(this);
        var input = self.siblings('input');
        var customCheckmarkHTML = '<span class="checkmark"></span>';
        self.append(input).append(customCheckmarkHTML);
      });
    }
  });
})(jQuery, window.DGS);