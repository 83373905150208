(function ($, DGS) {
  DGS.OnLoad.getInstance().register(function () {
    function addIDToRegion() {
      var regions = $('.contact-information .contact-information-item-group > h3');
      regions.each(function () {
        $(this)[0].id = $(this).text().toLowerCase();
      });
    }
    addIDToRegion();
  });
})(jQuery, window.DGS);