import WDHRichText from "../../../wdh-feature/wdh-rich-text/src/js/wdh-rich-text";
import WDHRichTextOverlay from "../../../wdh-feature/wdh-rich-text/src/js/variations/overlay";
import WDHImageSpot from "../../../wdh-feature/wdh-image-spot/src/js/wdh-image-spot";
import { addTrackingToProductClick } from "./feature/tracking/GA4-tracking";
(function (DGS) {
  DGS.OnLoad.getInstance().register(function () {
    var richText = new WDHRichText();
    richText.registerVariation(new WDHRichTextOverlay());
    richText.run();
    new WDHImageSpot().run();
    function controlAccessibilityAttributesForConditionalButton(button) {
      var input = button.parentNode.querySelector("input");
      var descriptionID = button.getAttribute("aria-describedby");
      input.addEventListener("change", function () {
        if (input.checked) {
          button.setAttribute("aria-disabled", "false");
          button.removeAttribute("aria-describedby");
        } else {
          button.setAttribute("aria-disabled", "true");
          button.setAttribute("aria-describedby", descriptionID);
        }
      });
    }
    function disableButtonIfConditionsNotAccepted(button) {
      var input = button.parentNode.querySelector("input");
      button.addEventListener("click", function (e) {
        if (!input.checked) {
          e.preventDefault();
        }
      });
    }
    function manageConditionalButtons() {
      var buttons = document.querySelectorAll(".conditional-btn");
      buttons.forEach(function (button) {
        controlAccessibilityAttributesForConditionalButton(button);
        disableButtonIfConditionsNotAccepted(button);
      });
    }
    manageConditionalButtons();
    var handleTrackingOnLinkClickToProductPage = function handleTrackingOnLinkClickToProductPage() {
      var linksToProduckPages = document.querySelectorAll("a[href*='/hearing-solutions/hearing-aids/'], a[href*='/professional-center/hearing-aids-pro/']");
      linksToProduckPages.forEach(function (link) {
        link.addEventListener("click", function (e) {
          addTrackingToProductClick(e, link.href);
        });
      });
    };
    handleTrackingOnLinkClickToProductPage();
  });
})(window.DGS);