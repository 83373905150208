var GENERIC_EVENT = "genericGAEvent";
var DGS = window.DGS;
DGS.GTMTracking = DGS.GTMTracking || {
  getDataLayer: function getDataLayer() {
    if (typeof dataLayer !== "undefined") {
      return dataLayer;
    }
    return {
      push: function push(data) {
        console.log("GTM not implemented. Debug data: ", data);
      }
    };
  }
};
export var isDataLayer = function isDataLayer() {
  return window.DGS && window.DGS.GTMTracking && window.DGS.GTMTracking.getDataLayer();
};
export var pushEventToDataLayer = function pushEventToDataLayer(eventData) {
  if (isDataLayer) {
    window.DGS.GTMTracking.getDataLayer().push(eventData);
  } else {
    console.log("DGS GTM not implemented. Debug data: ".concat(eventData));
  }
};
export var buildAndPushGenericGAEvent = function buildAndPushGenericGAEvent(category, action, label, value) {
  var eventData = {
    event: GENERIC_EVENT,
    event_category: category,
    event_action: action,
    event_label: label,
    event_value: value
  };
  pushEventToDataLayer(eventData);
};
export var trackHCLSearch = function trackHCLSearch(searchTerm) {
  return buildAndPushGenericGAEvent("hcl", "search", searchTerm, "");
};
export var trackHCLFormSubmit = function trackHCLFormSubmit(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "form_submitted", clinicName, "");
};
export var trackHCLClinicClick = function trackHCLClinicClick(clinicName) {
  return buildAndPushGenericGAEvent("hcl", "click_clinic", clinicName, "");
};
export var trackHearingTest = function trackHearingTest(stepNumber, stepName, scoreValue) {
  return buildAndPushGenericGAEvent("hearing_test", stepNumber, stepName, scoreValue);
};
export var trackProductClick = function trackProductClick(productName) {
  return buildAndPushGenericGAEvent("product_click", productName, "none", "none");
};
export var trackDownloadInResourceCenter = function trackDownloadInResourceCenter(fileType, fileName, selectedFiltersArray) {
  var getFilters = function getFilters(index) {
    return selectedFiltersArray[index].length > 0 ? selectedFiltersArray[index].join(", ") : "none";
  };
  var eventData = {
    event: GENERIC_EVENT,
    event_category: "download",
    event_action: fileType,
    event_label: fileName,
    event_selected_product: getFilters(0),
    event_selected_audience: getFilters(1)
  };
  pushEventToDataLayer(eventData);
};
export var addTrackingToProductClick = function addTrackingToProductClick(e, href, target) {
  e.preventDefault();
  var setTarget = target ? target : "_self";
  if (href.includes("/hearing-solutions/hearing-aids/") || href.includes("/professional-center/hearing-aids-pro/")) {
    var productName = href.split("/").at(-1);
    trackProductClick(productName);
  }
  window.open(href, setTarget);
};