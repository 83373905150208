window.DGS.OnLoad.getInstance().register(function () {
  var languageSelectorSpots = document.querySelectorAll('.component.language-selector-spot');
  languageSelectorSpots.forEach(function (component) {
    var languages = component.querySelectorAll('ul li a');
    var urlHost = document.location.host;
    var urlPath = document.location.pathname;
    var urlQueryString = document.location.search;
    var disableCurrentLanguage = function disableCurrentLanguage(link) {
      var label = link.innerText;
      var currentLanguage = document.createElement("span");
      currentLanguage.innerText = label;
      link.parentElement.append(currentLanguage);
      link.parentElement.removeChild(link);
    };
    var adjustURL = function adjustURL(link) {
      link.href = link.protocol + '//' + link.host + urlPath + urlQueryString;
    };
    var updateLanguage = function updateLanguage(link) {
      if (urlHost === link.host) {
        disableCurrentLanguage(link);
      } else {
        adjustURL(link);
      }
    };
    if (languages.length) {
      languages.forEach(updateLanguage);
      document.getElementById('header').classList.add('language-selector-active');
    }
  });
});