require('../../../../wdh-foundation/dgs-sharing/dgs-sharing.js');
(function ($, DGS) {
  var socialSharingConfig = {
    layout: "float",
    insertAfter: "#header",
    serviceElementsHeaderClass: "service-elements-header",
    serviceElementClass: "service-element",
    shareWindowDimensions: "width=626,height=436"
  };
  DGS.OnLoad.getInstance().register(function () {
    if ($("#social-sharing").length) {
      $("#social-sharing").sharingTools({
        layout: socialSharingConfig.layout,
        insertAfter: socialSharingConfig.insertAfter,
        serviceElementsHeaderClass: socialSharingConfig.serviceElementsHeaderClass,
        serviceElementClass: socialSharingConfig.serviceElementClass,
        shareWindowDimensions: socialSharingConfig.shareWindowDimensions
      });
      if ($('.blog-two-section-grid').length) {
        $('.blog-two-section-grid .section-two').prepend($("#social-sharing"));
        var isDesktop = window.innerWidth >= 768;
        var feedbackForm = $('.form.blog-feedback-form').first();
        $(window).on('scroll', function () {
          var currentScrollPos = $(window).scrollTop();
          if (currentScrollPos > 300) {
            $("#social-sharing").addClass('active');
          }
        });
      }
    }
  });
})(jQuery, window.DGS);