require('core-js');require('regenerator-runtime/runtime');import $ from 'jquery';
import cssLoader from '../lazy-loading-css-config';
cssLoader();
window.jQuery = $;
window.$ = $;
require('./ie-polyfills.js');
// webpack
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
require('../../../wdh-foundation/dgs-utils/dgs-utils.js');
require('jquery-nice-select/js/jquery.nice-select.min.js');
require('jquery-ui-dist/jquery-ui.min.js');
require('iframe-resizer');
require('jquery-bridget');
require('../../corporate-template/js/feature/data-hierarchy-spot/component-a-data-hierarchy.js');
require('../../corporate-template/js/feature/cookies.js');
require('../../corporate-template/js/feature/form.js');
require('../../corporate-template/js/feature/iframe.js');
require('../../corporate-template/js/feature/intro-banner.js');
require('../../corporate-template/js/feature/search.js');
require('../../corporate-template/js/feature/submenu-point-spot.js');
require('./feature/text-image-spot');
require('./feature/tracking/tracking-gtm.js');
require('./feature/form');
require('./feature/component-contact-information');
require('./feature/social-sharing');
require('../../corporate-template/js/project/main.js');
require('./main.js');
require('./feature/header');
require('./feature/language-selector-spot');
require('@demant/wdh-gdpr/lib/default.implementation.js');
require('@demant/megamenu');
require('@demant/burgermenu');
    if (document.querySelector('.accordion-spot')) {
      import(/* webpackChunkName: "chunk-num-1", webpackPrefetch: true */'./feature/accordion-spot/index')
        .then(function (foo) { console.log('.accordion-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .accordion-spot'); console.log(error); });
    }
    if (document.querySelector('.blog')) {
      import(/* webpackChunkName: "chunk-num-2", webpackPrefetch: true */'./feature/blog/blog-post/component-blog-post.js')
        .then(function (foo) { console.log('.blog LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .blog'); console.log(error); });
    }
    if (document.querySelector('.blog-feedback-form')) {
      import(/* webpackChunkName: "chunk-num-3", webpackPrefetch: true */'./feature/blog/blog-forms/blog-feedback-form')
        .then(function (foo) { console.log('.blog-feedback-form LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .blog-feedback-form'); console.log(error); });
    }
    if (document.querySelector('.blog-categories')) {
      import(/* webpackChunkName: "chunk-num-4", webpackPrefetch: true */'./feature/blog/blog-categories/component-blog-categories')
        .then(function (foo) { console.log('.blog-categories LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .blog-categories'); console.log(error); });
    }
    if (document.querySelector('.blog-list')) {
      import(/* webpackChunkName: "chunk-num-5", webpackPrefetch: true */'./feature/blog/blog-list/component-blog-list')
        .then(function (foo) { console.log('.blog-list LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .blog-list'); console.log(error); });
    }
    if (document.querySelector('.blog-subscription-form')) {
      import(/* webpackChunkName: "chunk-num-6", webpackPrefetch: true */'./feature/blog/blog-forms/blog-subscription-form')
        .then(function (foo) { console.log('.blog-subscription-form LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .blog-subscription-form'); console.log(error); });
    }
    if (document.querySelector('.related-blogs')) {
      import(/* webpackChunkName: "chunk-num-7", webpackPrefetch: true */'./feature/blog/blog-related-posts/blog-related-posts')
        .then(function (foo) { console.log('.related-blogs LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .related-blogs'); console.log(error); });
    }
    if (document.querySelector('.hearing-center-locator')) {
      import(/* webpackChunkName: "chunk-num-8", webpackPrefetch: true */'./feature/component-hearing-center-locator.js')
        .then(function (foo) { console.log('.hearing-center-locator LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-center-locator'); console.log(error); });
    }
    if (document.querySelector('.hearing-test-spot')) {
      import(/* webpackChunkName: "chunk-num-9", webpackPrefetch: true */'./feature/wdh-hearing-test.js')
        .then(function (foo) { console.log('.hearing-test-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .hearing-test-spot'); console.log(error); });
    }
    if (document.querySelector('.resource-center')) {
      import(/* webpackChunkName: "chunk-num-10", webpackPrefetch: true */'./feature/wdh-resource-center.js')
        .then(function (foo) { console.log('.resource-center LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .resource-center'); console.log(error); });
    }
    if (document.querySelector('.ui-elements')) {
      import(/* webpackChunkName: "chunk-num-11", webpackPrefetch: true */'../../corporate-template/js/feature/data-hierarchy-spot/component-data-hierarchy-dataset.js')
        .then(function (foo) { console.log('.ui-elements LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .ui-elements'); console.log(error); });
    }
    if (document.querySelector('.video-spot')) {
      import(/* webpackChunkName: "chunk-num-12", webpackPrefetch: true */'./feature/video-spot')
        .then(function (foo) { console.log('.video-spot LOADED'); })
        .catch(function (error) { console.log('An error occurred while loading the component for .video-spot'); console.log(error); });
    }
    if (document.querySelector('.accordion-spot')) {
      var link = document.querySelector('link[data-component^="accordion-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.blog')) {
      var link = document.querySelector('link[data-component^="blog"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-center-locator')) {
      var link = document.querySelector('link[data-component^="hearing-center-locator"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.resource-center')) {
      var link = document.querySelector('link[data-component^="resource-center"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-test-spot')) {
      var link = document.querySelector('link[data-component^="hearing-test-spot"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }
    if (document.querySelector('.hearing-test-result-page')) {
      var link = document.querySelector('link[data-component^="hearing-test-result-page"]');
      if (link) {
        link.setAttribute('href', link.getAttribute('data-href'));
      }
    }