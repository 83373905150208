(function () {
  "use strict";

  var burgerMenuConfig = {
      expanderSelector: ".header-mobile-top .nav-expander",
      menuItemsClass: "text-left",
      groupTwoLastLevels: false
    },
    megaMenuConfig = {
      hover: true
    };
  window.DGS.OnLoad.getInstance().register(function () {
    var sectionShown,
      $logo = $("#header .image"),
      $menu = $("#header .navigation"),
      $search = $("#header .search-field-spot"),
      $mainContainer = $("#wrapper > #header"),
      $languageSelector = $("#header .language-selector-spot"),
      headerSettings = {
        mainContainer: $mainContainer,
        determineSectionCallback: function determineSectionCallback(section) {
          sectionShown = section;
          return null;
        },
        sections: {
          mobile: {
            containers: [{
              className: "header-mobile-top",
              components: [$logo, $('<div class="nav-expander"><span>&nbsp;</span><span>&nbsp;</span><span>&nbsp;</span></div>'), $languageSelector]
            }, {
              className: "header-mobile-menu",
              components: [$menu]
            }]
          },
          desktop: {
            containers: [{
              className: "header-desktop-top",
              containers: [{
                className: "row-1",
                components: [$logo, $search, $menu, $languageSelector]
              }]
            }]
          }
        }
      };

    // BUILD MARKUP
    DGS.Builder.build(headerSettings);
    DGS.Event.getInstance().on(DGS.Events.BUILDER_DONE, function (settings) {
      if (settings !== headerSettings) return; // ONLY RUN POST SETUP IF IT'S THE RIGHT BUILDER

      $("#header").addClass("animate");

      // SETUP MEGA MENU
      $(".desktop-section .navigation .component-content > ul").megaMenu(megaMenuConfig);

      // SETUP BURGER MENU
      var backButton = '<div class="nav-back">Back</div>';
      $(".mobile-section .navigation").before(backButton);
      $(".mobile-section .navigation .component-content > ul").burgerMenu(burgerMenuConfig);

      // REGISTER RESIZER HANDLER
      DGS.Resizer.getInstance().register(function () {
        // ADJUST CONTENT SECTION BY ADDING BOTTOM MARGIN TO HEADER
        var headerHeight = $("#header .desktop-section > .inner-container").outerHeight();
        $("#header").prop("style").removeProperty("height");
        if (sectionShown === DGS.DESKTOP_LAYOUT) {
          if (headerHeight > 87) {
            $("#header").css("height", headerHeight);
          }
        }
      });
    });

    // Prevent browser from showing tooltip with title attributes
    var $attr = $("[title]");
    $attr.hover(function () {
      var title = $(this).attr("title");
      $(this).attr("temp-title", title);
      $(this).attr("title", "");
    });
    $attr.click(function () {
      var title = $(this).attr("temp-title");
      $(this).attr("title", title);
    });
    var menuItems = document.querySelectorAll(".dgs-mega-menu > li");
    menuItems.forEach(function (elem) {
      var subMenu = elem.querySelector("ul");
      if (subMenu) {
        // need to display submenu very quickly to grab width.
        subMenu.style.display = "block";
        var subMenuWidth = subMenu.offsetWidth;
        subMenu.style.display = "none";
        subMenu.style.transform = "translateX(-".concat(subMenuWidth / 2 - elem.offsetWidth / 2, "px)");
      }
    });
    function buildSearchButton(viewSelector) {
      var image = viewSelector.querySelector(".inner-container .component.image");
      var searchResultExists = document.querySelectorAll(".search-result-spot").length > 0;
      var searchButton = document.createElement("div");
      searchButton.classList.add("search-button");
      if (!searchResultExists) {
        image.parentNode.insertBefore(searchButton, image.nextSibling);
      }
    }
    function getSearchFieldPlaceholderText(text) {
      var searchFieldInput = document.querySelectorAll(".search-field input");
      if (searchFieldInput.length > 0) {
        searchFieldInput.forEach(function (input) {
          input.placeholder = text;
        });
      }
    }
    function addPlaceholderToSearchFieldInput() {
      var language = document.documentElement.lang;
      var dictionary = {
        Search: {
          Placeholder: "Search"
        }
      };
      window.DGS.Dictionary.getInstance().getTexts(dictionary, function () {
        getSearchFieldPlaceholderText(dictionary.Search.Placeholder);
      }, language);
    }
    function buildSearchFieldModal(viewSelector, inputContainer) {
      var containerForSearchField = document.createElement("div");
      containerForSearchField.classList.add("search-overlay-container");
      var searchField = inputContainer.querySelector(".search-field-spot");
      viewSelector.parentNode.appendChild(containerForSearchField);
      containerForSearchField.appendChild(searchField);
      addPlaceholderToSearchFieldInput();
    }
    function handleSearchBtnOnClick() {
      var searchButtons = document.querySelectorAll(".header .search-button");
      var searchContainers = document.querySelectorAll(".header .search-overlay-container");
      searchButtons.forEach(function (btn) {
        btn.addEventListener("click", function () {
          searchContainers.forEach(function (el) {
            return el.classList.toggle("show");
          });
          document.body.classList.toggle("no-scroll");
        });
      });
    }
    function buildSearch(container, inputContainer) {
      buildSearchButton(container);
      buildSearchFieldModal(container, inputContainer);
    }
    function positionLanguageSelectorForDesktop() {
      var languageSelector = document.querySelector(".desktop-section .language-selector-spot");
      var navigation = document.querySelector(".desktop-section .navigation.component");
      languageSelector.parentNode.insertBefore(languageSelector, navigation);
    }
    function positionLanguageSelectorForMobile() {
      var languageSelector = document.querySelector(".mobile-section .language-selector-spot");
      var searchBtn = document.querySelector(".mobile-section .search-button");
      languageSelector.parentNode.insertBefore(languageSelector, searchBtn);
    }
    function manageSearch() {
      var mobileContainer = document.querySelector(".header .mobile-section .header-mobile-top");
      var mobileInputContainer = document.querySelector(".header");
      buildSearch(mobileContainer, mobileInputContainer);
      var desktopContainer = document.querySelector(".header .desktop-section .header-desktop-top");
      buildSearch(desktopContainer, desktopContainer);
      handleSearchBtnOnClick();
    }
    function manageLanguageSelector() {
      positionLanguageSelectorForDesktop();
      positionLanguageSelectorForMobile();
    }
    manageSearch();
    manageLanguageSelector();
  });
})();