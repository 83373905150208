var cssLoader = function cssLoader() {
  var cssChunks = {
    '.accordion-spot.component': 'accordion-spot',
    '.blog-categories, .blog-feedback-form, .blog-subscription-form, .related-blogs, .blog-list, .blog, .blog-image-container, .blog-two-section-grid': 'blog',
    '.hearing-center-locator, .hearing-centor-locator-print, #map-canvas': 'hearing-center-locator',
    '.resource-center': 'resource-center',
    '.hearing-test-spot': 'hearing-test-spot',
    '.hearing-test-result-page': 'hearing-test-result-page'
  };
  Object.keys(cssChunks).map(function (key) {
    var element = document.querySelector(key);
    var link = document.querySelector("link[data-component^=\"".concat(cssChunks[key], "\"]"));
    if (element && link) {
      link.setAttribute('href', link.getAttribute('data-href'));
    }
  });
};
export default cssLoader;